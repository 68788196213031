.menahub {
    height: 100%;
    margin: 5% auto;
    max-width: 1280px;
    text-align: center;
    background: var(--gr);
    padding: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.menahub .logo {
    width: 119px;
    margin: auto;
    margin-left: 0;
}

.menahub .text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.menahub .text p {
    font-family: Roboto Slab;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
}

.direction_rtl .menahub .text p {
    font-family: 'ExpoArabic', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
}

.menahub.oryx .text p.title {
    font-size: 28px;
    font-weight: 400;
    line-height: 37px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--bg);
}

.menahub.oryx .text_2 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.menahub.oryx .logo.anahid {
    width: 59px;
}

.menahub.oryx .logo.nero {
    width: 68px;
}


@media (max-width: 1023px) {

    .direction_rtl .menahub .text p {
        font-size: 13px;
    }

    .menahub.oryx .text p.title {
        font-size: 20px;
        line-height: 30px;
    }
}