#showcasing {
    height: 100%;
    margin: 5% auto;
    max-width: 1280px;
}

#showcasing h1 {
    font-family: Roboto Slab;
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0em;
    margin: 0 auto 2% 5%;
    color: var(--ff);
}

.direction_rtl #showcasing h1 {
    margin: 0 5% 2% auto;
    font-family: 'ExpoArabic', sans-serif;
}

.direction_rtl #showcasing .showcasing h2.arab_fashion {
    font-family: 'ExpoArabic', sans-serif;
}

#showcasing .showcasing {
    width: 100%;
    height: 100%;
    min-height: 30vh;
    padding: 1% 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

#showcasing .showcasing h2.arab_fashion {
    font-family: Roboto Slab;
    font-size: 32px;
    font-weight: 300;
    line-height: 42px;
    letter-spacing: 0em;
    color: var(--cl);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1%;
}

.showcasing h2.arab_fashion span.span {
    display: inline-block;
    width: 10%;
    height: 1px;
    background: var(--cl);
}

#showcasing .showcasing .images_top {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
    margin: 2rem auto;
}

#showcasing .showcasing .images_top .img_1,
#showcasing .showcasing .images_top .img_2 {
    display: flex;
    justify-content: center;
    gap: 2rem;
}

#showcasing .showcasing .images_top .img_1 div,
#showcasing .showcasing .images_top .img_2 div {
    width: 100%;
}

#showcasing .showcasing .images_top .img_2 {
    gap: 1rem;
}

#showcasing .showcasing .images_top .img_1 img,
#showcasing .showcasing .images_top .img_2 img {
    flex: 1;
}

#showcasing .showcasing .images_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 1rem;
    margin: 2rem auto;
}

#showcasing .showcasing .images_bottom .img_between {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

#showcasing .showcasing .images_bottom .img_between div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}


@media (max-width: 1023px) {
    #showcasing .showcasing .images_top {
        gap: 5px;
        margin: 10px auto;
    }

    #showcasing .showcasing .images_bottom {
        gap: 5px;
        margin: 10px auto;
    }

    #showcasing .showcasing .images_bottom .img_between,
    #showcasing .showcasing .images_bottom .img_between div {
        gap: 5px;
    }

    #showcasing .showcasing .images_top .img_1,
    #showcasing .showcasing .images_top .img_2 {
        gap: 5px;
    }

    #showcasing .showcasing .images_top .img_2 {
        gap: 5px;
    }

    #showcasing .showcasing h2.arab_fashion {
        font-size: 13px;
        line-height: 22px;
    }

    #showcasing h1 {
        font-size: 22px;
        line-height: 32px;
    }

}