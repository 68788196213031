#about {
    height: 100%;
    margin: 5% auto;
    max-width: 1280px;
}

#about h1 {
    font-family: Roboto Slab;
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0em;
    margin: 0 auto 2% 5%;
    color: var(--ff);
}

#about .about_home {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 1fr 40%;
    width: 100%;
    height: 100%;
    min-height: 30vh;
    background: var(--cl);
    padding: 1% 5%;
}

#about .about_home .left {
    display: flex;
    flex-direction: column;
}

#about .about_home .left h2 {
    font-family: Roboto Slab;
    font-size: 28px;
    font-weight: 500;
    line-height: 37px;
    letter-spacing: 0em;
    margin-bottom: 12px;
}

#about .about_home .left p {
    font-family: Roboto Slab;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    margin-bottom: 12px;
}

#about .about_home .left h3 {
    font-family: Belleza;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    margin-bottom: 24px;
    width: 222px;
}

#about .about_home .left .go_about {
    background: var(--bg);
    color: var(--cl) !important;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    border-radius: 5px;
    width: fit-content;
}

.right .about_image {
    position: relative;
}

.right .about_image img {
    position: relative;
    z-index: 1;
}

.right .about_image::after {
    content: "";
    position: absolute;
    width: 90%;
    height: 110%;
    background: var(--ff);
    top: -60%;
    left: 15%;
    transform: translateY(50%);
}

.direction_rtl .right .about_image::after {
    left: auto;
    right: 15%;
}


.direction_rtl #about .about_home .left h2,
.direction_rtl #about .about_home .left p,
.direction_rtl #about .about_home .left h3 {
    font-family: 'ExpoArabic', sans-serif !important;
}

.direction_rtl #about h1 {
    margin: 0 5% 2% auto;
    font-family: 'ExpoArabic', sans-serif;
}


@media (max-width: 768px) {
    #about .about_home {
        grid-template-columns: 1fr;
        padding: 5%;
    }

    #about .about_home .left h2 {
        font-size: 20px;
        line-height: 25px;
    }

    #about .about_home .left p {
        font-size: 13px;
        line-height: 20px;
    }

    #about .about_home .left h3 {
        font-size: 20px;
        line-height: 25px;
        width: 50%;
    }

    #about .about_home .right {
        width: 80%;
        margin: 15%;
    }

    #about h1 {
        font-size: 22px;
        line-height: 32px;
    }
}