nav {
    position: sticky;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    height: 10vh;
    background: var(--cl);
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    direction: ltr;
    font-family: 'Roboto Slab', serif;
}

.direction_rtl ul.links {
    flex-direction: row-reverse;
}

nav .logo {
    float: left;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 24px;
    color: var(--ff);
}

nav .logo img {
    width: 7.3rem;
}

nav .links {
    float: right;
    padding: 0;
    margin: 0;
    width: max-content;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

nav .links li {
    cursor: pointer;
}

nav .links a {
    display: block;
    font-size: 18px;
    font-weight: bold;
    color: var(--bl);
    text-decoration: none;
    position: relative;
    margin: 0 25px;
}


#nav-toggle {
    position: absolute;
    top: -10vh;
}

nav .icon-burger {
    display: none;
}

nav .icon-burger .line {
    width: 30px;
    height: 5px;
    background: var(--bg);
    margin: 5px;
    border-radius: 3px;
    transition: all .5s ease-in-out;
}

nav .active {
    color: var(--bg) !important;
}

a.no_active.active {
    border-bottom: none;
}


nav a.logo {
    cursor: pointer;
}

nav button.ChangeLang,
nav .go_about {
    background: var(--bg);
    color: var(--cl) !important;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    width: max-content;
    border-radius: 5px;
}

.direction_rtl nav {
    font-family: 'ExpoArabic', sans-serif;
}

@media (max-width: 1023px) {
    nav {
        flex-direction: row;
    }

    nav .logo {
        float: none;
        width: auto;
        justify-content: center;
    }

    nav .links {
        float: none;
        position: fixed;
        z-index: 9;
        left: 0;
        right: 0;
        top: 10vh;
        bottom: 100%;
        width: auto;
        height: auto;
        flex-direction: column !important;
        justify-content: flex-start;
        gap: 1rem;
        background: var(--cl);
        overflow: hidden;
        transition: all .5s ease-in-out;
    }

    nav .links a {
        font-size: 20px;
        color: var(--bl);
        padding: 5px 1em;
    }

    nav .logo img {
        width: 5rem;
    }

    nav button.ChangeLang,
    nav .go_about {
        font-size: 12px;
    }

    nav :checked~.links {
        bottom: 0;
    }

    nav .icon-burger {
        display: block;
    }

    nav :checked~.icon-burger .line:nth-child(1) {
        transform: translateY(10px) rotate(225deg);
    }

    nav :checked~.icon-burger .line:nth-child(3) {
        transform: translateY(-10px) rotate(-225deg);
    }

    nav :checked~.icon-burger .line:nth-child(2) {
        opacity: 0;
    }
}