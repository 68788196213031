#contact {
    width: 100%;
    height: 100%;
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5rem;
    background: #e8c56d;
}

#contact .contact {
    display: flex;
    width: 90%;
    height: 100%;
    margin: auto;
    gap: 5rem;
}

#contact .contact h2 {
    color: #5a070b;
    position: relative;
    width: max-content;
    padding-bottom: 5px;
    text-transform: uppercase;
}

#contact .contact h2 span {
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: #5a070b;
    border-radius: 50%;
}

#contact .all_contact {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    align-items: center;
    justify-items: center;
}

#contact .right,
#contact .center,
#contact .left {
    display: flex;
    justify-content: center;
}

#contact .center {
    flex-direction: column;
    gap: 1rem;
    direction: ltr;
}


#contact .links {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

#contact .links li {
    cursor: pointer;
    margin: 0 1rem;
}

#contact .links a {
    display: block;
    padding: 10px 0;
    font-size: 1.2rem;
    font-weight: bold;
    color: #5a070b;
    position: relative;
}

#contact .links a::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #5a070b;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}

#contact .links a:hover::before {
    visibility: visible;
    transform: scaleX(1);
    color: #5a070b;
}

.left a.logo {
    display: inline-block;
    width: 150px;
    cursor: pointer;
}

#contact .center a {
    color: #5a070b;
    font-weight: bold;
    font-size: 1.4rem;
}

#contact .center div {
    display: flex;
    gap: 1rem;
}

#contact .center div a {
    color: var(--bg);
    font-size: 24px;
    transition: all 0.5s ease-in-out;
}

#contact .center div a:hover {
    transform: translateY(-10px);
}

#contact .center a.whatsapp,
#contact .center a.mailto {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: Roboto Slab;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
}

#contact .center a.mailto svg,
#contact .center a.whatsapp svg {
    color: var(--bg);
    font-size: 24px;
    transition: all 0.5s ease-in-out;
}

#contact .center a.whatsapp:hover svg,
#contact .center a.mailto:hover svg {
    transform: translateX(-10px);
}

@media (max-width: 1023px) {
    #contact .all_contact {
        grid-template-columns: 1fr;
        padding: 2rem;
    }

    #contact .links a {
        padding: 5px 0;
        font-size: 1rem;
    }

    #contact .center a,
    #contact .center div a {
        font-size: 1rem;
    }

    .left a.logo {
        width: 7rem;
    }
}