#layout {
    height: 100%;
    margin: 5% auto;
    max-width: 1280px;
}

#layout h1 {
    font-family: Roboto Slab;
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0em;
    margin: 0 auto 2% 5%;
    color: var(--ff);
}

.direction_rtl #layout h1 {
    margin: 0 5% 2% auto;
    font-family: 'ExpoArabic', sans-serif;
}

#layout .layout {
    width: 100%;
    height: 100%;
    padding: 1% 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--cl);
    gap: 5px;
}


@media (max-width: 768px) {
    #layout h1 {
        font-size: 22px;
        line-height: 32px;
    }
}