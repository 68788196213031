#header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 50vh;
}

#header .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 60vh;
    background: url("../../img/header.webp") no-repeat;
    background-size: cover;
    background-position: center;
    color: var(--ff);
    position: relative;
}

#header .header .text {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: linear-gradient(71.04deg, rgba(23, 23, 23, 0.5) -20.28%, rgba(198, 198, 198, 0) 78.08%);
    background-blend-mode: multiply;
    position: absolute;
    top: 0;
    left: 0;
}

#header .header .text h2 {
    font-family: Belleza;
    font-size: 60px;
    font-weight: 400;
    line-height: 66px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 10px;
}

#header .header .text p {
    font-family: Roboto Slab;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    margin-bottom: 1rem;
    text-align: center;
}

.header .text p:nth-child(3) {
    margin-bottom: 0 !important;
}

#header .header .link_header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    direction: ltr;
}

#header .header .link_header a img {
    width: 90px;
}

#header .header .link_header a.about {
    background: var(--cl);
    color: var(--bg) !important;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    border-radius: 5px;
}


#header .header .link_header a svg {
    font-size: 32px;
    color: var(--ff);
}


.direction_rtl #header .header .text h2,
.direction_rtl #header .header .text p {
    font-family: 'ExpoArabic', sans-serif !important;
}

.link_header.about {
    flex-direction: row-reverse;
}

@media (max-width:768px) {
    #header .header {
        min-height: 50vh;
    }

    #header .header .text h2 {
        font-size: 30px;
        line-height: 33px;
        margin-bottom: 5px;
    }

    #header .header .text p {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 8px;
    }

    #header .header .link_header a img {
        width: 60px;
    }

    #header .header .link_header a.about {
        font-size: 12px;
    }

    #header .header .link_header a svg {
        font-size: 16px;
    }

}