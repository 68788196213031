#event_flow {
    height: 100%;
    margin: 5% auto;
    max-width: 1280px;
}

#event_flow h1 {
    font-family: Roboto Slab;
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0em;
    margin: 0 auto 2% 5%;
    color: var(--ff);
}

.direction_rtl #event_flow h1 {
    margin: 0 5% 2% auto;
    font-family: 'ExpoArabic', sans-serif;
}

#event_flow .event_flow {
    width: 90%;
    height: 100%;
    min-height: 30vh;
    padding: 10px;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    background: var(--ff);
    margin: auto 5%;
    margin-bottom: 1rem;
}

#event_flow .event_flow .right,
#event_flow .event_flow .left {
    width: 100%;
    background: var(--bg);
    height: 100%;
}

#event_flow .event_flow .right img {
    height: 100%;
    object-fit: cover;
}

#event_flow .event_flow .left {
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

#event_flow .event_flow .left .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
}

#event_flow .event_flow .left .top img {
    width: 43px;
}

#event_flow .event_flow .left .top h2 {
    font-family: Belleza;
    font-size: 32px;
    color: var(--cl);
    text-transform: uppercase;
    font-weight: 100;
}

#event_flow .event_flow .left .botton_day {
    display: flex;
    align-items: center;
    gap: 25%;
}

.event_flow .left .botton_day .day {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.event_flow .left .botton_day .day p {
    font-size: 30px;
    color: var(--ff);
}

.event_flow .left .botton_day .day p:last-child {
    font-size: 1rem;
}

.event_flow .left .botton_day .day small,
.direction_rtl .botton_day_ar small {
    color: var(--cl);
}

#event_flow .event_flow .left p.event_flow_1 {
    font-family: Roboto Slab;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0em;
    color: var(--ff);
    width: 75%;
}


#event_flow .event_flow.bottom {
    gap: 20px;
}

#event_flow .event_flow.bottom .left {
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
}

#event_flow .event_flow.bottom .left .title h2 {
    color: var(--cl);
}

#event_flow .event_flow.bottom .left .title h2:last-child {
    color: var(--ff);
}

#event_flow .event_flow.bottom .left .info,
#event_flow .event_flow.bottom .left p {
    font-family: Roboto Slab;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0em;
    color: var(--ff);
}

.botton_day_ar {
    display: none;
}

.direction_rtl #event_flow .event_flow .left .botton_day,
.direction_rtl #event_flow .event_flow .left p.event_flow_1 {
    display: none;
}

.direction_rtl #event_flow .event_flow {
    direction: ltr;
    font-family: 'ExpoArabic', sans-serif;
}

.direction_rtl #event_flow .event_flow .left {
    direction: rtl;
    font-family: 'ExpoArabic', sans-serif;
}

.direction_rtl .botton_day_ar {
    display: flex;
    flex-wrap: wrap;
    gap: 5rem;
    font-family: 'ExpoArabic', sans-serif;
}

.direction_rtl .botton_day_ar p {
    font-size: 18px;
    color: var(--ff);
    font-family: 'ExpoArabic', sans-serif;
}

.direction_rtl .botton_day_ar p.event_flow_1_ar {
    font-family: 'ExpoArabic', sans-serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0em;
    color: var(--ff);
    width: 75%;
}

.direction_rtl .botton_day_ar .day_2_ar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-family: 'ExpoArabic', sans-serif;
}

.direction_rtl #event_flow .event_flow.bottom .left .title h2 {
    color: var(--ff);
    font-family: 'ExpoArabic', sans-serif;
}

.direction_rtl #event_flow .event_flow .left .top h2 {
    font-family: 'ExpoArabic', sans-serif;
    font-size: 32px;
}

.direction_rtl #event_flow .event_flow.bottom .left p {
    font-family: 'ExpoArabic', sans-serif;
}

.direction_rtl #event_flow .event_flow.bottom .left .title h2:last-child {
    color: var(--cl);
    font-family: 'ExpoArabic', sans-serif;
}

.table {
    width: fit-content;
    margin: auto;
    border: 3px solid var(--cl);
    border-radius: 10px;
}



table {
    margin: auto;
    background: var(--bg);
    border-collapse: collapse;
    border-radius: 10px;
}

table thead {
    color: var(--bg);
    font-family: Roboto Slab;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
}

th,
td {
    text-align: left;
    padding: 20px 30px;
    background: var(--ff);
    border: 3px solid var(--bg);
    border-radius: 10px;
    font-family: Roboto Slab;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    color: var(--bg);
}

.direction_rtl th,
.direction_rtl td {
    text-align: right;
    font-family: 'ExpoArabic', sans-serif;
}


a.whatsapp_register {
    font-family: Roboto Slab;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    width: 184px;
    height: 56px;
    border-radius: 10px;
    background: var(--cl);
    align-items: center;
    justify-content: center;
    margin: 2rem auto;
    color: var(--bg);
}


@media (max-width: 1023px) {
    #event_flow h1 {
        font-size: 22px;
        line-height: 32px;
    }

    #event_flow .event_flow .left {
        gap: 1rem;
    }

    #event_flow .event_flow {
        grid-template-columns: 1fr;
    }

    #event_flow .event_flow .left .top h2 {
        font-size: 16px;
    }

    .event_flow .left .botton_day .day {
        gap: 1rem;
    }

    .event_flow .left .botton_day .day p {
        font-size: 20px;
        color: var(--ff);
    }

    .event_flow .left .botton_day .day p:last-child {
        font-size: 10px;
    }

    #event_flow .event_flow .left p.event_flow_1 {
        font-size: 10px;
        width: 100%;
    }

    #event_flow .event_flow.bottom .left .info,
    #event_flow .event_flow.bottom .left p {
        font-size: 12px;
    }

    .direction_rtl .botton_day_ar {
        gap: 1rem;
        justify-content: space-between;
    }

    .direction_rtl .botton_day_ar p {
        font-size: 13px;
    }

    .direction_rtl .botton_day_ar p.event_flow_1_ar {
        font-size: 10px;
        width: 60%;
    }

    th,
    td {
        padding: 5px 10px;
        border: 1px solid var(--bg);
        border-radius: 5px;
        font-size: 10px;
        line-height: 16px;
    }

    a.whatsapp_register {
        font-size: 13px;
        line-height: 20px;
        width: 150px;
        height: 50px;
        border-radius: 5px;
        margin: 1rem auto;
    }
}